@import url('https://fonts.googleapis.com/css?family=Ubuntu|Roboto');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');

$font-heading: 'Roboto', sans-serif;
$font-body: 'Ubuntu', sans-serif;

.content {
	margin: auto;
}

.container {
	padding: 0.01em 16px;
}

.center {
	text-align:center;
}

.body {
}
