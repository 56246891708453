.toast {
	max-width: 100% !important;
	margin-top: 1em;
	margin-bottom: 1em;
}

.width-sm, input.form-control, select.form-control {
	margin-left: 1em !important;
	margin-right: 1em !important;
}
