@import url(https://fonts.googleapis.com/css?family=Ubuntu|Roboto);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
body {
	height: 100%;
	color: #777;
	line-height: 1.8;
	background-color: #212529!important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  margin: auto; }

.container {
  padding: 0.01em 16px; }

.center {
  text-align: center; }

input.form-control {
  margin-left: 1em !important; }

/* Dice */
.toast.dice {
	max-width: 100% !important;
	margin-top: 1em;
	margin-bottom: 1em;
}

input.form-control.diceInput, select.form-control.diceInput {
	margin-left: 1em !important;
	margin-right: 1em !important;
	min-width: 7em;
}

/* Dice */
.toast.dice {
	max-width: 100% !important;
	margin-top: 1em;
	margin-bottom: 1em;
}

input.form-control.diceInput, select.form-control.diceInput {
	margin-left: 1em !important;
	margin-right: 1em !important;
	width: 7em;
}




.toast {
	max-width: 100% !important;
	margin-top: 1em;
	margin-bottom: 1em;
}

.width-sm, input.form-control, select.form-control {
	margin-left: 1em !important;
	margin-right: 1em !important;
}

.toast {
  margin-top: 1em;
  margin-bottom: 1em; }

.width-sm, input.form-control, select.form-control {
  min-width: 2em !important; }

input.form-control {
  margin-left: 0 !important; }

input.form-control {
  margin-left: 0 !important; }

input.form-control {
  margin-left: 0 !important; }

.Parallax-Content {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.circle {
  border-radius: 50%; }

h3.center {
  margin-bottom: 2em; }

a.navbar-brand {
  color: white !important; }

a.nav-link {
  color: white;
  text-decoration: none; }

button.btn-outline-secondary {
  background-color: #343a40 !important;
  margin-right: 2px; }

