.Parallax-Content {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.circle {
	border-radius: 50%;
}

h3.center {
	margin-bottom: 2em;
}
