a.navbar-brand {
  color: white!important;
}
a.nav-link {
  color: white;
  text-decoration: none;
}

button.btn-outline-secondary {
  background-color: #343a40!important;
  margin-right: 2px;
}
